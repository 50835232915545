<template>
    <figure class="flex flex-wrap items-center justify-evenly gap-x-4 gap-y-2 rounded-xl from-base-300/70 to-base-200
                   p-8 text-center xs:w-4/5 md:flex-nowrap"
            :class="{ 'flex-row-reverse bg-gradient-to-tl': mirror, 'bg-gradient-to-tr': !mirror }">
        <div class="mx-auto rounded-full bg-base-300 p-2">
            <Icon name="account" size="32" class="text-primary-content/60" />
        </div>
        <div class="grow space-y-2">
            <div class="rating">
                <span class="mask mask-star size-8 bg-primary" />
                <span class="mask mask-star size-8 bg-primary" />
                <span class="mask mask-star size-8 bg-primary" />
                <span class="mask mask-star size-8 bg-primary" />
                <span class="mask mask-star size-8 bg-primary" />
            </div>
            <blockquote>
                <p>{{ quote }}</p>
            </blockquote>
            <figcaption class="font-medium">
                <i class="text-accent">
                    {{ author }}
                </i>
                <div class="text-base-content/50">
                    <span class="text-base-content/25">From:</span> <NuxtLink rel="noopener" :to="link" target="_blank">
                        Facebook
                    </NuxtLink>
                </div>
            </figcaption>
        </div>
    </figure>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Review',

    props: {
        mirror: {
            type: Boolean,
            default: false
        },

        quote: {
            type: String,
            required: true
        },

        author: {
            type: String,
            required: true
        },

        link: {
            type: String,
            required: true
        }
    }
});
</script>
